
import React, { Link } from 'react';
import { ReferenceField, ReferenceManyField, Datagrid, SimpleShowLayout, TextField, Show, useTranslate, Labeled } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { locationDict } from '../../util/variant'
import { ANIMAL_TYPE_CAGE, ANIMAL_TYPE_FREE } from '../../util/constant'
import {
    ClientField, OrderStatusField, FormattedDateField, OrderTypeField, DistanceField, DurationField,
    FullNameClientField, FullNameDriverField
} from '../../control/field/index'
import { OrderCancelButton } from '../../control/button/index'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color: '#F4004E',
    },
});


const CircuitField = ({ source, record = {}, basePath }) => {
    // if(record.order)
    console.log("Order type: " + record.order_type);
    if (record.order_type === '0' || record.order_type === '1') return <div />;
    return <Labeled label="Circuit touristique">
        <ReferenceManyField basePath={basePath} reference="order_stop" filter={{ order_id: record['id'] }} addLabel={true} label="Circuit touristique">
            <Datagrid>
                <TextField label="Nom" source="name" />
                <TextField label="Adresse" source="address" />
            </Datagrid>
        </ReferenceManyField></Labeled>;

}

CircuitField.defaultProps = {
    addLabel: false,
};


// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <OrderCancelButton record={data}/> */}
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_running.show')}: #{record ? `${record.order_number}` : ''}</span>;

};



// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderRunning = ({ staticContext, permissions, ...props }) => {
    const classes = useStyles();
    if (permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
        <Show title={<Title />} {...props} actions={<ShowActions />}>

            <SimpleShowLayout>
                <div />
                <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock} />
                <OrderTypeField source="order_type" label="Type de commande" className={classes.inlineBlock} />
                <div />
                {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="show">
                    <TextField source="name" />
                </ReferenceField> */}
                {/* <OrderActivityTypeField source="order_type" label= "Type d'activité" className={classes.inlineBlock}/> */}
                <div />
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure de création" />
                <OrderStatusField source="status" label="État" className={classes.colorPrimary} />
                <FullNameClientField label="Client" className={classes.inlineBlock} />
                <FullNameDriverField label="Jockey" className={classes.inlineBlock} />
                <TextField label="Numéro de véhicule" source="v_plate_number" />

                {/* <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
                <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
                <div/>
                <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
                <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/> */}

                <div />
                <TextField source="from_address" label="Adresse de départ" />
                <TextField source="to_address" label="Adresse d'arrivée" />
                <CircuitField />
                <div />
                <DistanceField label="Distance" className={classes.inlineBlock} />
                <DurationField label="Durée" className={classes.inlineBlock} />
                <div />
                <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée" />

                <br />
                <Divider />

                {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                {/* <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/> */}
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>Montants</Typography>
                <TextField source="promo_code" label="Code promo" className={classes.inlineBlock} />
                <TextField source="discount_amount" label="Montant réduit(€)" className={classes.inlineBlock} />
                <div />
                <TextField source="wait_amount" label="Montant d'attente(€)" className={classes.inlineBlock} />


                <TextField source="commission_amount" label="Commission(€)" className={classes.inlineBlock} />
                <TextField source="driver_amount" label="Commission Jockey(€)" className={classes.inlineBlock} />

                <div />
                {/* <TextField source="original_total" label="Total original(€)" className={classes.inlineBlock}/> */}
                <TextField source="stripe_fee" label="Frais Stripe estimé(€)" className={classes.inlineBlock} />
                <TextField source="total" label="Total(€)" className={classes.inlineBlock} />

                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(€)"/> */}
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}

                <br />
                <Divider />

                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>Déroulement</Typography>
                {/* <div/>
                <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                <div />
                <FormattedDateField source="at_pick_time" format="format_date_time" label="Jockey sur place" className={classes.inlineBlock} />
                <TextField source="wait_min" label="Temps d'attente(mn)" />
                <FormattedDateField source="start_time" format="format_date_time" label="Début" className={classes.inlineBlock} />
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
                <div />
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/>
                <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation"/> */}
                {/* <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/>
          
            
            <TextField source="total" label="Prix(€)"/>
            <TextField source="commission_amount" label="Commission(€)"/>
            <TextField source="driver_amount" label="Commission chauffeur(€)"/>
            
            
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2" color="primary">Déroulement</Typography>
            <div/>
            
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="start_time" format="format_date_time" label="Démarrage" className={classes.inlineBlock}/>
            
            <div/> */}
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/> */}

                {/* <TextField source="pt_name" label="Mode de paiement"/> */}
                {/* <TextField source="driver.email" label="Chauffeur"/> */}

                {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
            </SimpleShowLayout>
        </Show>
    )
};


export default ShowOrderRunning;