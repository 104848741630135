import React, { Fragment } from 'react';
import { AutocompleteInput, ReferenceInput, List, Datagrid, TextField, Pagination, downloadCSV, DateInput, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import { locationDict, locationTypes } from '../../util/variant'
import jsonExport from 'jsonexport/dist'
import frLocale from "date-fns/locale/fr";
import { FormattedDateField, OrderTypeField } from '../../control/field';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order.list')}</span>;
};

const TypeTextField = ({ source, record = {}, label }) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof (locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}

TypeTextField.defaultProps = {
    addLabel: true,
};

const exporter = orders => {
    const dataForExport = orders.map(order => {
        const { id, client_id, driver_id, status, c_email, c_first_name, c_last_name, c_mobile, d_email, d_first_name, d_last_name, d_mobile, ...dataForExport } = order; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        return dataForExport;
    });
    const BOM = "\uFEFF"
    jsonExport(dataForExport, {
        headers: ['order_date', 'order_type_name', 'driver_user_code',
            'accept_status', 'order_status', 'driver_accept_rate',
            'client_name', 'start_time', 'from_address', 'end_time', 'to_address',
            'rating_on_driver', 'driver_rating_avg', 'comment_on_driver',
            'total', 'base_amount', 'duration_amount', 'distance_amount',
            'wait_amount', 'increase_amount', 'cancel_amount', 'tips_amount',
            'driver_commission_rate', 'driver_amount',
            'promo_code', 'promo_code_client_count', 'promo_code_all_count',
            'discount_amount', 'commission_amount'

        ], // order fields in the export
        rename: ['DATE', 'TYPE DE COMMANDE', 'JOCKEY',
            'STATUT COURSE', 'ETAPES COURSE', 'TAUX ACCEPTATION MOYEN DES COURSES PAR JOCKEY(%)',
            'CLIENT', 'HEURE DE DEPART', 'ADRESSE DE DEPART', "HEURE D'ARRVIEE", "ADRESSE D'ARRIVEE",
            'NOTE CLIENT', 'NOTE MOYEN CLIENT DU JOCKEY APRES COURSE EN COURS', 'AVIS CLIENT',
            'PRIX TOTAL COURSE PAYE PAR CLIENT', 'PRIX DE BASE', 'FRAIS TEMPS', 'FRAIS DISTANCE',
            "FRAIS ATTENTE PAYANT", "MAJORATION", "FRAIS ANNULATION", "POURBOIRE",
            "TAUX COMMISSION JOCKEY(%)", "TOTAL JOCKEY",
            "CODE PROMO", "NOMBRE UTILISATION CODE PAR CLIENT", "NOMBRE DE FOIS OU CE CODE A ETE UTILISE EN TOUT PAR L'ENSEMBLE DES CLIENTS",
            "MONTANT REDUCTION ACCORDEE AU CLIENT", "TOTAL MONTANT PERCU PAR URBAN JOCKEY"],
        verticalOutput: false,
        booleanTrueString: 'Oui',
        booleanFalseString: 'Non',

        // rowDelimiter: "\t",
        // forceDelimiter: true,
    }, (err, csv) => {
        downloadCSV(`${BOM} ${csv}`, 'commandes'); // download as 'posts.csv` file
    });
};

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <DateInput label="Date de début" source="from_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />
        <DateInput label="Date de fin" source="to_date" alwaysOn resettable options={{ format: 'DD/MM/YYYY', locale: frLocale }} />
        {/* <TextInput label="Jockey" source="user_code" alwaysOn resettable /> */}
        <ReferenceInput alwaysOn label="Jockey" reference="driver" source="driver_id"

            perPage={999999}>
            <AutocompleteInput

                options={{
                    suggestionsContainerProps: {
                        style: {
                            zIndex: 99999
                        }
                    }
                }}
                optionText="user_code" />
        </ReferenceInput>
        <TextInput label="Code remise" source="promo_code" alwaysOn resettable />

    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
const ListGain = ({ permissions, ...props }) => (
    <List title={<Title />} {...props} pagination={<ListPagination />} perPage={25}
        filters={<ListFilter />} empty={false} reference="report"
        bulkActionButtons={<BulkActionButtons />} exporter={exporter}>
        {/* headers: ['create_date', 'order_type_name', 'driver_user_code',
            'accept_status', 'order_status', 'driver_accept_rate',
            'client_name', 'pickup_time', 'from_address', 'arrive_time', 'to_address',
            'rating_on_driver', 'driver_rating_avg', 'comment_on_driver',
            'total', 'base_amount', 'duration_amount', 'distance_amount',
            'wait_amount', 'increase_amount', 'cancel_amount', 'tips_amount',
            'driver_commission_rate', 'driver_amount',
            'promo_code', 'promo_code_client_count', 'promo_code_all_count',
            'discount_amount', 'commission_amount' */}

        {/* rename: ['DATE', 'TYPE DE COMMANDE', 'JOCKEY',
            'STATUT COURSE', 'ETAPES COURSE', 'TAUX ACCEPTATION MOYEN DES COURSES PAR JOCKEY',
            'CLIENT', 'HEURE DE DEPART', 'ADRESSE DE DEPART', "HEURE D'ARRVIEE", "ADRESSE D'ARRIVEE",
            'NOTE CLIENT', 'NOTE MOYEN CLIENT DU JOCKEY APRES COURSE EN COURS', 'AVIS CLIENT',
            'PRIX TOTAL COURSE PAYE PAR CLIENT', 'PRIX DE BASE', 'FRAIS TEMPS', 'FRAIS DISTANCE',
            "FRAIS ATTENTE PAYANT", "MAJORATION", "FRAIS ANNULATION", "POURBOIRE",
            "TAUX COMMISSION JOCKEY", "TOTAL JOCKEY",
            "CODE PROMO", "NOMBRE UTILISATION CODE PAR CLIENT", "NOMBRE DE FOIS OU CE CODE A ETE UTILISE EN TOUT PAR L'ENSEMBLE DES CLIENTS",
            "MONTANT REDUCTION ACCORDEE AU CLIENT", "TOTAL MONTANT PERCU PAR URBAN JOCKEY"], */}

        <Datagrid rowClick="">
            {/* <FormattedDateField source="create_time" label="Date" locales="fr-FR" /> */}
            <TextField source="order_date" label="Date" />
            {/* <OrderTypeField source="order_type" label="Type de commande" /> */}
            <TextField source="order_type_name" label="Type de commande" />
            <TextField source="driver_user_code" label="Jockey" />
            <TextField source="status" label="Statut course" />
            <TextField source="order_status" label="Etapes course" />

            <TextField source="driver_accept_rate" label="Taux acceptation moyen des courses par Jockey(%)" />
            <TextField source="client_name" label="Client" />
            <TextField source="start_time" label="Heure de départ" />
            <TextField source="from_address" label="Adrese de départ" />
            <TextField source="end_time" label="Heure d'arrivée" />

            <TextField source="to_address" label="Adresse d'arrivée" />
            <TextField source="rating_on_driver" label="Note client" />
            <TextField source="driver_rating_avg" label="Note moyen client du Jockey après la course en cours" />
            <TextField source="comment_on_driver" label="Avis client" />
            <TextField source="total" label="Prix total course payé par client" />

            <TextField source="base_amount" label="Prix de base" />
            <TextField source="duration_amount" label="Frais temps" />
            <TextField source="distance_amount" label="Frais distance" />
            <TextField source="wait_amount" label="Frais attente payant" />
            <TextField source="increase_amount" label="Majoration" />

            <TextField source="cancel_amount" label="Frais annulation" />
            <TextField source="tips_amount" label="Pourboire" />
            <TextField source="driver_commission_rate" label="Taux commission Jockey(%)" />
            <TextField source="driver_amount" label="Total Jockey" />
            <TextField source="promo_code" label="Code remise" />

            <TextField source="promo_code_client_count" label="Nombre utilisation code par client" />
            <TextField source="promo_code_all_count" label="Nombre de fois ou ce code a été utilisé en tout par l'ensemble des clients" />
            <TextField source="discount_amount" label="Montant réduction accordée au client" />
            <TextField source="commission_amount" label="Total montant perçu par Urban Jockey" />


        </Datagrid>
    </List>
);

export default ListGain;

