
import React from 'react';
import { TextInput, TextField, Edit, SimpleForm, SelectInput, required, useTranslate, number, minLength, maxLength } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
        <DeleteButton label="ra.action.delete" redirect='list' {...props}/>}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.name.edit')}: {record ? `${record.name}` : ''}</span>;
};


const EditName = ({classes, permissions, ...props })  => {
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="list" 
                toolbar={<CustomToolbar permissions={permissions} />}
                defaultValue={{ role: 'Admin' }}>
                
            <TextInput source="name" label="Nom de Jockey" validate={required()} fullWidth/>
            
        </SimpleForm>
    </Edit>
};

export default EditName;