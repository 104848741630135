
import React from 'react';
import { TextInput, Labeled, RadioButtonGroupInput, Create, SimpleForm, SelectInput, required, useTranslate} 
from 'react-admin';
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.create')}</span>;
};

const CreateVehicle = ({permissions, ...props}) => {
    const translate = useTranslate();
    
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="plate_number" label = "Numéro de véhicule"/>
            {/* label="Date de 1e mise en circulation" */}
            
            <MuiPickersUtilsProvider utils={MomentUtils}  style={{'width':'800px'}}>
            
            <DateInput source="circulation_date"  label="&nbsp;&nbsp;Date"
            style={{width:'800'}}
            autoComplete="" options={{ format: '  DD/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            <Labeled label="(de première mise en circulation)">
            </Labeled>
            </MuiPickersUtilsProvider>
            
            
            <RadioButtonGroupInput source="is_locked" label="Verrouillé?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue='0'/>
        </SimpleForm>
    </Create>
)};

export default CreateVehicle;
