// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
import * as Constant from '../util/constant'

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    console.log('convertFileToBase64: file = ');
    console.log(file.rawFile);
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const uploadFileProvider = requestHandler => (type, resource, params) => {
    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'vehicle_type') {
        console.log('Uploaded files:');
        console.log(params.data.map_icon);
        console.log(params.data.select_icon);

        if(params.data.map_icon == null && params.data.select_icon == null){
            return requestHandler(type, resource, params);
        }

        var files = [];
        if(params.data.map_icon != null){
            params.data.map_icon.doc_type = Constant.UB_DOC_TYPE_VEHICLE_TYPE_MAP;
            files = [...files, params.data.map_icon];
        }

        if(params.data.select_icon != null){
            params.data.select_icon.doc_type = Constant.UB_DOC_TYPE_VEHICLE_TYPE_SELECT;
            files = [...files, params.data.select_icon];
        }

        delete params.data['map_icon'];
        delete params.data['select_icon'];

        params.data.files = files;

        console.log('Files length = ' + params.data.files.length);
        const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
        const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

        return Promise.all(newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((base64, index) => ({
                src: base64,
                title: `${newFiles[index].title}`,
                doc_type: `${newFiles[index].doc_type}`,
            })))
            .then(transformedNewFiles => requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    files: [...transformedNewFiles, ...formerFiles],
                },
            }));
        
    }

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'location') {
        console.log('Uploaded files:');
        console.log(params.data.photo);
        
        if(params.data.photo == null){
            return requestHandler(type, resource, params);
        }

        files = [];
        if(params.data.photo != null){
            files = [...files, params.data.photo];
        }

        delete params.data['photo'];
        
        params.data.files = files;

        console.log('Files length = ' + params.data.files.length);
        const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
        const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

        return Promise.all(newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((base64, index) => ({
                src: base64,
                title: `${newFiles[index].title}`,
                
            })))
            .then(transformedNewFiles => requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    files: [...transformedNewFiles, ...formerFiles],
                },
            }));
    }


    if ((type === 'UPDATE') && resource === 'profile_photo') {
        console.log('Uploaded files:');
        console.log(params.data.binary_photo);
        
        if(params.data.binary_photo == null){
            return requestHandler(type, resource, params);
        }

        var files = [];
        if(params.data.binary_photo != null){
            files = [...files, params.data.binary_photo];
        }

        delete params.data['binary_photo'];
        
        params.data.files = files;

        console.log('Files length = ' + params.data.files.length);
        const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
        const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

        return Promise.all(newFiles.map(convertFileToBase64))
            .then(base64Files => base64Files.map((base64, index) => ({
                src: base64,
                title: `${newFiles[index].title}`,
                
            })))
            .then(transformedNewFiles => requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    files: [...transformedNewFiles, ...formerFiles],
                },
            }));
    }


    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'doc') {
        // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.pictures is not an array, but single object
        console.log('Uploaded files:');
        console.log(params.data.files);

        if(params.data.files == null){
            return requestHandler(type, resource, params);
        }

        if (params.data.files && params.data.files.length) {
            // only freshly dropped pictures are instance of File
            console.log('Files length = ' + params.data.files.length);
            const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
            const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

            return Promise.all(newFiles.map(convertFileToBase64))
                .then(base64Files => base64Files.map((base64, index) => ({
                    src: base64,
                    title: `${newFiles[index].title}`,
                })))
                .then(transformedNewFiles => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [...transformedNewFiles, ...formerFiles],
                    },
                }));
        }
        else{
            var formerFile = null;
            if(!(params.data.files.rawFile instanceof File))
                formerFile = params.data.files;

            var formerFiles = [];
            if(formerFile != null)
                formerFiles = [formerFile];
            
            var newFile = null;
            if(params.data.files.rawFile instanceof File)
                newFile = params.data.files;
            
            var newFiles = [];
            if(newFile != null)
                newFiles = [newFile];

            return Promise.all(newFiles.map(convertFileToBase64))
                .then(base64Files => base64Files.map((base64, index) => ({
                    src: base64,
                    title: `${newFiles[index].title}`,
                })))
                .then(transformedNewFiles => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [...transformedNewFiles, ...formerFiles],
                    },
                }));
        }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default uploadFileProvider;