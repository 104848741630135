import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    crudUpdate,
    fetchEnd,
    fetchStart,
    required,
    showNotification,
  
    Button,
    ReferenceInput,
    AutocompleteInput,
    SaveButton,
    SimpleForm,
    TextInput,
    NumberInput,
    RadioButtonGroupInput,
    UPDATE,
    FileField,
    Loading,
    TextField,
    REDUX_FORM_NAME
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { push } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import TextInput from 'ra-input-rich-text';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ActionSend from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import {BACKEND_UMS_URL} from '../../util/variant'
import {httpClient} from '../../util/function'

import { DateInput, TimeInput} from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

import jsonDataProvider from '../../provider/json-data-provider';
import moment from 'moment';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth;

class EventEditButton extends Component {
    state = {
        message: "",
        error: false,
        showDialog: false,
        showLoading: false,
       
    }
    

    // fetchData = (selectedIds) => {
    //     const token = localStorage.getItem('token');
    //     const url = BACKEND_URL + '/list-doc/' + selectedIds;
    //     console.log('Get doc list url: ' + url);
    //     const request = new Request(url, {
    //         method: 'GET',
    //         headers: new Headers({ 'Content-Type': 'application/json', 
    //                                 'Authorization': `Bearer ${token}` }),
    //     })
    //     fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }

    //             response.json().then(json => {
    //                 console.log("Get doc list response json: " + JSON.stringify(json));
    //                 var fileArray = [];
    //                 var i = 0;
    //                 json.forEach(function(doc){
    //                     const files = doc.files;
    //                     if(files !== null && typeof files !== 'undefined'){
    //                         files.forEach(function(file){
    //                             fileArray[i] = file;
    //                             i ++;
    //                         });
    //                     }
    //                 });
                
    //                 this.setState({files: fileArray});
    //                 console.log("#Send mail of files:");
    //                 console.log(this.state.files);

    //                 this.setState({ showDialog: true, showLoading: false });
    //             });

                
    //         });
    // };

    // handleClick = () => {
    //      console.log(this.props);
    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });

    //         params = params.substring(0, params.length - 1);
    //     }

    //     this.fetchData(params);
    // };

    // handleCloseClick = () => {
    //     this.setState({ showDialog: false });
    // };

    // handleSaveClick = () => {
    //     const { submit } = this.props;

    //     // Trigger a submit of our custom quick create form
    //     // This is needed because our modal action buttons are oustide the form
    //     submit('send-email');
    // };

    // handleSubmit = values => {
    //     console.log('Batch email button props:');
    //     console.log(this.props);
    //     // Dispatch an action letting react-admin know a API call is ongoing
    //     this.setState({isLoading: true});

    //     console.log('Send mail values:');
    //     console.log(values);

    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });
    //     }

    //     if(params.length > 0)
    //         params = params.substring(0, params.length - 1);

    //     var url = BACKEND_URL + '/mails/' + params;
    //     const token = localStorage.getItem('token');

    //     axios({
    //         url: url,
    //         method: 'POST',
    //         data: values,
    //         headers: {'Authorization': `Bearer ${token}`},
            
    //       }).then((response) => {
    //             this.setState({ showDialog: false, isLoading: false });
    //       })
    //     .catch(error => showNotification(error));
      
    // }

    

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Send notice props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { basePath, crudUpdate, resource, redirect, record } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DocStatus.REJECTED;
        // showNotification("Error", 'error');
        
        // this.setState({ showDialog: true, error: true });

        var plateNumber = record.plate_number;
        // console.log('Send notice button values: ' + JSON.stringify(values)); 
        var data = { event_date: values['event_date'],
                     start_time: values['start_time'], end_time: values['end_time'],
                     start_km: values['start_km'], end_km: values['end_km'],
                     driver_id: values['driver_id'],
                     comment: values['comment'], revision: values['revision'],};

        crudUpdate('vehicle_event', record.id, data, basePath, 'show', true);
        this.setState({ showDialog: false });
            
        // dataProvider(UPDATE, 'vehicle_event', data)
        //     .then(() => {
        //         console.log("Event updated");
        //         this.setState({ showDialog: false });
        //         refresh();
        //     })
        //     .catch(error => {
        //         console.log("Event update error: " + JSON.stringify(error));
        //         showNotification("Error", 'error');
        //         this.setState({error: true, message: "Erreur"});
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        // this.setState({ showDialog: false });
    };

    

    validate = values => {
        const errors = {};
        if (!values.driver_id) {
            errors.driver_id = ['Ce champ est requis'];
        }

        if (!values.event_date) {
            errors.event_date = ['Ce champ est requis'];
        }

        if (!values.start_time) {
            errors.start_time = ['Ce champ est requis'];
        }
        else{
            if(values.start_time !== 'undefined'){
                if (values.start_time.length !== 5) {
                    errors.start_time = ['Doit être au format ab:xy, par exemple: 06:15'];
                }
                else{
                    const vals = values.start_time.split(":");
                    if(vals.length !== 2){
                        errors.start_time = ['Doit être au format ab:xy, par exemple: 06:15'];
                    }
                    else{
                        if(vals[0].length !== 2 || vals[1].length !== 2){
                            errors.start_time = ['Doit être au format ab:xy, par exemple: 06:15'];
                        }
                        else 
                            if(isNaN(+vals[0]) || isNaN(+vals[1])){
                                errors.start_time = ['Doit être au format ab:xy tandis que ab et xy sont des nombres, par exemple: 06:15'];
                            }
                            else if(+vals[0] < 0 || +vals[0] > 23){
                                errors.start_time = ["Doit être au format ab:xy tandis que ab est l'heure du jour, de 00 à 23"];
                            }
                            else if(+vals[1] < 0 || +vals[1] > 59){
                                errors.start_time = ["Doit être au format ab:xy tandis que xy est la minute, de 00 à 59"];
                            }
                    }
                }
            }
            
        }

        if (!values.end_time) {
            // errors.end_time = ['Ce champ est requis'];
        }
        else{
            if (values.end_time.length !== 5) {
                errors.end_time = ['Doit être au format ab:xy, par exemple: 06:15'];
            }
            else{
                const vals = values.end_time.split(":");
                if(vals.length !== 2){
                    errors.end_time = ['Doit être au format ab:xy, par exemple: 06:15'];
                }
                else{
                    if(vals[0].length !== 2 || vals[1].length !== 2){
                        errors.end_time = ['Doit être au format ab:xy, par exemple: 06:15'];
                    }
                    else 
                        if(isNaN(+vals[0]) || isNaN(+vals[1])){
                            errors.end_time = ['Doit être au format ab:xy tandis que ab et xy sont des nombres, par exemple: 06:15'];
                        }
                        else if(+vals[0] < 0 || +vals[0] > 23){
                            errors.end_time = ["Doit être au format ab:xy tandis que ab est l'heure du jour, de 00 à 23"];
                        }
                        else if(+vals[1] < 0 || +vals[1] > 59){
                            errors.end_time = ["Doit être au format ab:xy tandis que xy est la minute, de 00 à 59"];
                        }
                        else if(+(values.start_time.replace(":", "")) > +(values.end_time.replace(":", ""))){
                            errors.start_time = ["L'heure de départ doit être avant l'heure de départ"];
                            errors.end_time = ["L'heure de retour doit être après l'heure de départ"];
                        }
                }
            }
        }

        
        // if (!values.end_time) {
        //     errors.end_time = ['Ce champ est requis'];
        // }

        // if (values.start_time && values.end_time && moment(values.end_time).isBefore(moment(values.start_time))) {
        //     errors.start_time = ["L'heure de départ doit être avant l'heure de départ"];
        //     errors.end_time = ["L'heure de retour doit être après l'heure de départ"];
        // }

        if(values.start_km === 0){

        }
        else
            if (!values.start_km) {
                errors.start_km = ['Ce champ est requis'];
            }

        // if (!values.end_km) {
        //     errors.end_km = ['Ce champ est requis'];
        // }

        if (values.start_km && values.end_km && +(values.start_km) > +(values.end_km)) {
            errors.end_km = ['Le kilomérage de retour doit être plus ou équal que le kimétrage de retour'];
            errors.start_km = ['Le kilomérage de départ doit être moins ou équal que le kimétrage de retour'];
        }

        // if (!values.age) {
        //     errors.age = ['The age is required'];
        // } else if (values.age < 18) {
        //     errors.age = ['Must be over 18'];
        // }
        return errors
    };

    render() {
        const { showDialog, showLoading, error, message } = this.state;
        const { isSubmitting, record } = this.props;
        
       
        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let submit;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="">
                    <EditIcon />
                </Button>
                { this.state.isLoading && 
                    <Loading loadingPrimary="" loadingSecondary="" />
                    }
                <Dialog
                    fullWidth={true}
                    maxWidth="1000xs"
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Éditer un événement"
                >
                    <DialogTitle>Éditer un événement</DialogTitle>
                    <DialogContent>
                    <Form validate={this.validate}
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="event-create-form" onSubmit={handleSubmit}>
                                <div>
                                <span>Numéro de véhicule : {record.plate_number}</span>

                                <ReferenceInput disabled label="Jockey assigné" reference="driver" source="driver_id"
                                defaultValue={record.driver_id}
                                perPage={999999}>
                            <AutocompleteInput 
                            
                            options={{
                                suggestionsContainerProps: {
                                    style: {
                                    zIndex: 99999
                                    }
                                }
                                }}
                            optionText="user_code"/>
                        </ReferenceInput>

                        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateInput source="event_date"  label="&nbsp;&nbsp;Date"
            defaultValue={record.event_date}
            autoComplete="" options={{ format: '  DD/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            />
            </MuiPickersUtilsProvider>
            
            {/* <MuiPickersUtilsProvider utils={MomentUtils}>

                <TimeInput source="start_time" label="&nbsp;&nbsp;Heure de départ" 
                defaultValue={record.start_time}
                    options={{ format: '  HH:mm' }}/>
                

            </MuiPickersUtilsProvider> */}
            <br/>
            {/* <span>Heure de départ actuel : {record.start_time}</span>
            <br/> */}
            
        <TextInput source="start_time" label="Heure de départ" defaultValue={record.start_time}/>
        <br/>
    
    
            <NumberInput source="start_km" label="Kilométrage au départ (km)"  style={{'width':'300px'}}
                defaultValue={record.start_km}
            />
            <br/>

            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimeInput source="end_time" label="&nbsp;&nbsp;Heure de retour" 
                defaultValue={record.end_time}
                    options={{ format: '  HH:mm' }}/>

            </MuiPickersUtilsProvider> */}
            {/* {record.end_time !== 'null' && record.end_time !== '' && record.end_time !== 'undefined' && <div><span>Heure de retour actuel : {record.end_time}</span><br/><TextInput source="end_time" label="Nouvelle heure de retour"/></div>}
            {(record.end_time === 'false' || record.end_time === 'null' || record.end_time === '' || record.end_time === 'undefined') && <div><TextInput source="end_time" label="Heure de retour"/></div>}
            <br/> */}
            <TextInput source="end_time" label="Heure de retour" defaultValue={record.end_time}/>
        <br/>
            
            <NumberInput source="end_km" label="Kilométrage au retour (km)" style={{'width':'300px'}}
                defaultValue={record.end_km}
            />

            <RichTextInput multiline source="comment" label="Commentaire" fullWidth={true}
                defaultValue={record.comment}
            />
            
            <RadioButtonGroupInput source="revision" label="Révision nécessaire ?" 
            choices={[
                { id: '0', name: 'Non' },
                { id: '1', name: 'Oui' },
            ]} initialValue={record.revision}/>
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >
                <span>{this.state.message}</span>
                        
                    </Form>

                        
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <CancelIcon color="primary"/>
                        </Button>
                        <Button label="Sauvegarder" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('event-create-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <SaveIcon color="primary"/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('notice-send-form')(state)
});

EventEditButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


// const mapDispatchToProps = {
//     change,
//     fetchEnd,
//     fetchStart,
//     showNotification,
//     submit
// };

export default connect(undefined, { crudUpdate })(EventEditButton);

// export default connect(mapStateToProps, mapDispatchToProps)(
//     EventEditButton
// );