
import React from 'react';
import { TextInput, Create, SimpleForm, SelectInput, required, number, useTranslate} from 'react-admin';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {locationTypes, GOOGLE_API_KEY} from '../../util/variant'
import RichTextInput from 'ra-input-rich-text';


// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.email_template.create')}</span>;
};

const validateLatVal = (value, allValues) => {
  if (value <= -90 || value > 90) {
      return 'Doit être en [-90, 90]';
  }
  return [];
};

const validateLat = [required(), number(), validateLatVal];

const validateLngVal = (value, allValues) => {
  if (value < -180 || value > 180) {
      return 'Doit être en [-180, 180]';
  }
  return [];
};

const validateLng = [required(), number(), validateLngVal];

const CreateEmailTemplate = ({permissions, ...props}) => {
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
        <TextInput source="code" label="Code" fullWidth validate={required()} />
                <TextInput source="name" label="Nom" fullWidth validate={required()} />
                <TextInput source="c_subject" label="Objet" fullWidth validate={required()} />
                <RichTextInput source="c_body" label="Contenu" fullWidth validate={required()} />
                <TextInput source="c_sender" label="Email d'expéditeur" 
                    initialValue=""
                    validate={required()}/>
                <TextInput source="c_sender_name" label="Nom d'expéditeur" 
                    initialValue="UrbanJockey"
                    validate={required()}/>
            
        </SimpleForm>
    </Create>
)};

export default CreateEmailTemplate;

{/* options={{ format: 'dd/MM/yyyy' }  */}