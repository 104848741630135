
import React, {useState} from 'react';
import {ArrayInput, FormDataConsumer, NumberInput, CheckboxGroupInput, SimpleFormIterator, ReferenceInput, TextInput, Create, SimpleForm, SelectInput, required, useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { TimeInput } from 'react-admin-date-inputs2';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { ListButton} from 'react-admin';
// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {orderTypes, markupTypes, unitTypes} from '../../util/constant'
import {activityTypes} from '../../util/variant'
// import { formValueSelector } from 'redux-form';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    
});
const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.price_time.create')}</span>;
};

const DaysField = ({ record }) => {
    // console.log('Edit time price record: ' + JSON.stringify(record));
    const [checkEveryDay, setCheckEveryDay] = useState(false);
    
    // props.record.every_day[0] === '1';
    
    const onChangeEveryDay = (e) => {
        // alert(e.target.checked);
        // alert('xxx');
        setCheckEveryDay(!checkEveryDay);
    }

    return <div><CheckboxGroupInput source="every_day" label="" choices={[
        { id: '1', name: 'Tous les jours' },
    ]} onChange={(e) => onChangeEveryDay(e)}/>

        <FormDataConsumer subscription={{ values: true }}>

        {({ formData, ...rest }) => 
            !checkEveryDay &&
            <CheckboxGroupInput source="days" label="" choices={[
                { id: 1, name: 'Lundi' },
                { id: 2, name: 'Mardi' },
                { id: 3, name: 'Mercredi' },
                { id: 4, name: 'Jeudi' },
                { id: 5, name: 'Vendredi' },
                { id: 6, name: 'Samedi' },
                { id: 7, name: 'Dimanche' },
            ]} />
        }
        </FormDataConsumer>
    </div>
}


const CreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CreatePriceTime = ({permissions, ...props })   => {
    const classes = useStyles();
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return (
    
    <Create title={<Title/>} {...props} actions={<CreateActions/>}>
        <SimpleForm redirect="list">
        {/* <ReferenceInput source="vehicle_type_id" perPage={99999} className={classes.inlineBlock}
            reference="vehicle_type" label="Type de véhicule">
                <SelectInput optionText="name"/>
            </ReferenceInput> */}
            
            <SelectInput source="order_type" label="Type de commande" validate={required()} 
                choices={orderTypes} className={classes.inlineBlock}/>
            {/* <SelectInput source="activity_type" label="Type de pause" validate={required()} 
                        choices={activityTypes} className={classes.inlineBlock}/> */}
                            
            <div/>
            {/* <ArrayInput source="configuration">
                <SimpleFormIterator> */}
                {/* <CheckboxGroupInput source="days" choices={[
                        { id: 0, name: 'Tous les jours' },
                        { id: 1, name: 'Lundi' },
                        { id: 2, name: 'Mardi' },
                        { id: 3, name: 'Mercredi' },
                        { id: 4, name: 'Jeudi' },
                        { id: 5, name: 'Vendredi' },
                        { id: 6, name: 'Samedi' },
                        { id: 7, name: 'Dimanche' },
                    ]} /> */}
                    <DaysField/>

                    <MuiPickersUtilsProvider utils={MomentUtils}>

<TimeInput source="from_time" label="Heure de début"
    options={{ format: 'HH:mm' }}/>


<TimeInput source="to_time" label="Heure de fin"
    options={{ format: 'HH:mm' }}/>

</MuiPickersUtilsProvider>
                {/* <SelectInput source="type" label="Type" validate={required()} choices={markupTypes} defaultValue="0"/> */}
                <NumberInput source="markup" label="Valeur"/>
                <SelectInput source="markup_unit" label="Unité" validate={required()} choices={unitTypes} defaultValue="0"/>
                {/* </SimpleFormIterator> */}
            {/* </ArrayInput> */}
            
        </SimpleForm>
    </Create>
)};

export default CreatePriceTime;

{/* options={{ format: 'dd/MM/yyyy' }  */}