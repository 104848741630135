
import React from 'react';
import { TextInput, Create, SimpleForm, SelectInput, required, number, minLength, maxLength, useTranslate} from 'react-admin';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {locationTypes, GOOGLE_API_KEY} from '../../util/variant'



// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.name.create')}</span>;
};

const validateLatVal = (value, allValues) => {
  if (value <= -90 || value > 90) {
      return 'Doit être en [-90, 90]';
  }
  return [];
};

const validateLat = [required(), number(), validateLatVal];

const validateLngVal = (value, allValues) => {
  if (value < -180 || value > 180) {
      return 'Doit être en [-180, 180]';
  }
  return [];
};

const validateLng = [required(), number(), validateLngVal];

const validatePostalCodeVal = (value, allValues) => {
  if (value < 1000 || value > 95999) {
      return 'Doit être en [01000, 95999]';
  }
  return [];
};

const validatePostalCode = [required(), minLength(5), maxLength(5), validatePostalCodeVal];

const CreateName = ({classes, permissions, ...props })  => {
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" label="Nom de Jockey" validate={required()} fullWidth/>
            
            
        </SimpleForm>
    </Create>
)};

export default CreateName;
