
import React from 'react';
import { TextInput, ImageInput, ImageField, Create, FormDataConsumer, SimpleForm, SelectInput, required, number, minLength, maxLength, useTranslate} from 'react-admin';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';

// import { DateInput } from 'react-admin-date-inputs2';
// import { DateInput } from 'react-admin-date-inputs2';
// import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
// import frLocale from "date-fns/locale/fr";

// DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth
import {locationTypes, GOOGLE_API_KEY} from '../../util/variant'
import { LocationType } from '../../util/constant';



// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.location.create')}</span>;
};

const validateLatVal = (value, allValues) => {
  if (value <= -90 || value > 90) {
      return 'Doit être en [-90, 90]';
  }
  return [];
};

const validateLat = [required(), number(), validateLatVal];

const validateLngVal = (value, allValues) => {
  if (value < -180 || value > 180) {
      return 'Doit être en [-180, 180]';
  }
  return [];
};

const validateLng = [required(), number(), validateLngVal];

const validatePostalCodeVal = (value, allValues) => {
  if (value < 1000 || value > 95999) {
      return 'Doit être en [01000, 95999]';
  }
  return [];
};

const validatePostalCode = [required(), minLength(5), maxLength(5), validatePostalCodeVal];

const CreateLocation = ({classes, permissions, ...props })  => {
  const translate = useTranslate();
    const uploadFileText = translate('ra.input.file.upload_single') + '(' + translate('max_size') + '100KB)';
    // const fetchPlace = place => {
    //     // const { setFieldsValue } = this.props.form;
  
    //     const addressComponents = place.address_components;
    //       addressComponents.forEach(addressComponent => {
    //         const types = addressComponent.types;
    //         if(types.includes('locality') && types.includes('political')){
    //         //   setFieldsValue({
    //         //     city: addressComponent.long_name,
    //         //   }, () => {});  
    //         }
    //         else
    //           if(types.includes('postal_code')){
    //             // setFieldsValue({
    //             //   postal_code: addressComponent.long_name,
    //             // }, () => {});  
    //           }
    //       });
  
    //     const placeId = place['place_id'];
  
    //     // setFieldsValue({
    //     //   address: place['formatted_address']
    //     // }
    //     // , 
    //     // () => console.log('after'));
    //     console.log('before');
  
    //     // this.setState({city: placeId});
    //     const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    //     const url = 'https://maps.googleapis.com/maps/api/place/details/json?place_id=' + placeId + '&key=' + GOOGLE_API_KEY;
    //     axios.get(proxyUrl + url)
    //     .then(res => {
    //       console.log(res.data.result);
    //       const location = res.data.result.geometry.location;
    //       console.log(location);
    //     //   this.setState({lat: location.lat, lng: location.lng});
          
    //     })
    //     .catch(error => console.log(error));
    //   }
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return(
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
        {/* <SelectInput source="type" label="Type" validate={required()} choices={locationTypes}/> */}
            <TextInput source="name" label="Nom" validate={required()} fullWidth/>
            <TextInput source="address" label="Adresse" validate={required()} multiline fullWidth/>
            <TextInput source="postal_code" label="Code postal"/>
            {/* <Autocomplete
                className='MuiInputBase'
                placeholder='Adresse*'
                style={{width: '100%'}}
                onPlaceSelected={(place) => {
                  console.log('Place result:');
                  console.log(place);
                  fetchPlace(place);
                  
                }}
                types={['address',]}

            /> */}
            
            <TextInput source="lat" label="Latitude" validate={validateLat}/>
            <TextInput source="lng" label="Longitude" validate={validateLng}/>
            {/* <FormDataConsumer subscription={{ values: true }}>
                 {({ formData, ...rest }) => 
                    (
                    formData.type === LocationType.PLACE) && */}
            <ImageInput source="photo" label="Icone" accept="image/*" maxSize='100000' placeholder={uploadFileText} validate={required()}>
            <ImageField source="url" title="Fichier" />
            </ImageInput>
                 {/* }
             </FormDataConsumer> */}
            
            
            {/* <DateInput source="birth_day" label="Date de naissance"
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            
        </SimpleForm>
    </Create>
)};

export default CreateLocation;

{/* options={{ format: 'dd/MM/yyyy' }  */}