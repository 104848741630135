import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    crudCreate,
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,

    CREATE,
    FileField,
    Loading,
    REDUX_FORM_NAME
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { push } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import TextInput from 'ra-input-rich-text';
import ActionSend from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';
import IconCancel from '@material-ui/icons/Cancel';
import axios from 'axios';
import { BACKEND_UMS_URL } from '../../util/variant'
import { httpClient } from '../../util/function'

import jsonDataProvider from '../../provider/json-data-provider';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

class NoticeSendButton extends Component {
    state = {
        showDialog: false,
        showLoading: false,

    }

    // fetchData = (selectedIds) => {
    //     const token = localStorage.getItem('token');
    //     const url = BACKEND_URL + '/list-doc/' + selectedIds;
    //     console.log('Get doc list url: ' + url);
    //     const request = new Request(url, {
    //         method: 'GET',
    //         headers: new Headers({ 'Content-Type': 'application/json', 
    //                                 'Authorization': `Bearer ${token}` }),
    //     })
    //     fetch(request)
    //         .then(response => {
    //             if (response.status < 200 || response.status >= 300) {
    //                 throw new Error(response.statusText);
    //             }

    //             response.json().then(json => {
    //                 console.log("Get doc list response json: " + JSON.stringify(json));
    //                 var fileArray = [];
    //                 var i = 0;
    //                 json.forEach(function(doc){
    //                     const files = doc.files;
    //                     if(files !== null && typeof files !== 'undefined'){
    //                         files.forEach(function(file){
    //                             fileArray[i] = file;
    //                             i ++;
    //                         });
    //                     }
    //                 });

    //                 this.setState({files: fileArray});
    //                 console.log("#Send mail of files:");
    //                 console.log(this.state.files);

    //                 this.setState({ showDialog: true, showLoading: false });
    //             });


    //         });
    // };

    // handleClick = () => {
    //      console.log(this.props);
    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });

    //         params = params.substring(0, params.length - 1);
    //     }

    //     this.fetchData(params);
    // };

    // handleCloseClick = () => {
    //     this.setState({ showDialog: false });
    // };

    // handleSaveClick = () => {
    //     const { submit } = this.props;

    //     // Trigger a submit of our custom quick create form
    //     // This is needed because our modal action buttons are oustide the form
    //     submit('send-email');
    // };

    // handleSubmit = values => {
    //     console.log('Batch email button props:');
    //     console.log(this.props);
    //     // Dispatch an action letting react-admin know a API call is ongoing
    //     this.setState({isLoading: true});

    //     console.log('Send mail values:');
    //     console.log(values);

    //     const selectedIds = this.props.selectedIds;
    //     var params = '';
    //     if(typeof selectedIds !== 'undefined'){
    //         selectedIds.forEach(function(selectedId) {
    //             params += selectedId + ',';
    //           });
    //     }

    //     if(params.length > 0)
    //         params = params.substring(0, params.length - 1);

    //     var url = BACKEND_URL + '/mails/' + params;
    //     const token = localStorage.getItem('token');

    //     axios({
    //         url: url,
    //         method: 'POST',
    //         data: values,
    //         headers: {'Authorization': `Bearer ${token}`},

    //       }).then((response) => {
    //             this.setState({ showDialog: false, isLoading: false });
    //       })
    //     .catch(error => showNotification(error));

    // }

    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Send notice props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { basePath, crudUpdateMany, resource, selectedIds, toClient } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DocStatus.REJECTED;

        console.log('Send notice button values: ' + JSON.stringify(values));
        // , reason: values['reason']
        // crudCreate('notice', {}, basePath, 'show', true);

        var data = { data: { driver_ids: selectedIds, published: 1, title: values['title'], content: values['content'] } };
        if (toClient)
            data = { data: { client_ids: selectedIds, published: 1, title: values['title'], content: values['content'] } };

        dataProvider(CREATE, 'notice', data)
            // dataProvider(CREATE, 'notice', {data: { recipients: selectedIds, values}})
            .then(({ data }) => {
                this.setState({ showDialog: false });
            })
            .catch(error => {
                showNotification(error.message, 'error');
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
            });

        this.setState({ showDialog: false });
    };

    render() {
        const { showDialog, showLoading } = this.state;
        const { isSubmitting, record } = this.props;


        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let submit;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label='Message'>
                    <MessageIcon />
                </Button>
                {this.state.isLoading &&
                    <Loading loadingPrimary="" loadingSecondary="" />
                }
                <Dialog
                    fullWidth={true}
                    maxWidth="1000xs"
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Envoi d'un message"
                >
                    <DialogTitle>Envoi d'un message</DialogTitle>
                    <DialogContent>
                        <Form
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {
                                submit = handleSubmit
                                return (
                                    <form id="notice-send-form" onSubmit={handleSubmit}>
                                        <div>
                                            {/* <TextInput source="sender_name" validate={required()} initialValue="VTSafe"/> */}
                                            <TextInput multiline source="title" label="Titre" fullWidth={true} validate={required()} />
                                            <RichTextInput multiline source="content" label="Contenu" fullWidth={true} validate={required()} />
                                        </div>

                                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                    </form>
                                )
                            }}
                        >

                        </Form>


                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}


                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel color="secondary" />
                        </Button>
                        <Button label="Envoyer" onClick={() =>
                            // { cancelable: true } required for Firefox
                            // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                            document
                                .getElementById('notice-send-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                        } saving={isSubmitting}>
                            <ActionSend />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('notice-send-form')(state)
});

NoticeSendButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
    NoticeSendButton
);