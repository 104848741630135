import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as Constant from '../../util/constant';

export const OrderTypeFilterField = ({ record }) => {
    return <span>{record.id == Constant.OrderType.IMMEDIATE ? 'Immédiate' : "Réservation à l'avance"}</span>;
};

const OrderTypeField = ({ record = {}, label}) => {
    return <div>
         <Typography variant="body2">
        {record.order_type === "0"? 'Immédiate' : record.order_type === "1"? "Réservation à l'avance" : record.order_type === "2"? "Circuit touristique immédiate - Sans pause" : record.order_type === "5"? "Circuit touristique réservation - Sans pause" : record.order_type === "3"? "Circuit touristique immédiate - Avec pause": "Circuit touristique réservation - Avec pause"}
        </Typography>
    </div>;
}

OrderTypeField.defaultProps = {
    addLabel: true,
};

export default OrderTypeField;
