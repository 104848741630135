import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
  x="0px" y="0px" width="229px" height="50px" 
  viewBox="0 0 229 50" enable-background="new 0 0 229 50" 
  >  <image id="image0" width="229" height="50" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOUAAAAyCAQAAAAJ+9QKAAAABGdBTUEAALGPC/xhBQAAAAJiS0dE
AP+Hj8y/AAAACXBIWXMAADoiAAA6IgEiXG7jAAAAB3RJTUUH5gMMCyUU/8N8LAAABFt6VFh0UmF3
IHByb2ZpbGUgdHlwZSB4bXAAAFiFxVjdsrM2DLzXU/QRjGXL+HFIgLtvppd9/O7KnIRgknxJp22Y
AwRsabX6PZG/fv0pf+ATB6uiV13LWIINpnaxXFIMFi1bsWqLzjEu6+VyWWPE82qJT3LRnGYNaS4h
KdaOEJTGMhVszFqmtORkuEKgKjbFqKsuMei1jDqV0bDRZiqzIQZ+t6stRflOqAFokq3EoVN7cVvu
SO5i8OzCHem2I4Y8pjkHiQS3Fn+kFhcNcQaegAMitGjFs0FNEw7D2yueRvW/uOKacR40Spx92YRH
CedBSwyHI27mRaDAyhxTSnYwLYq/pHljSTiCTjBnLf6JS8GquDji4porD0cScY44z00BEBUt8A8Z
KSPMgga+f0QBCHAVHBGtOlMVDGHFz3sbBIStBcQSVSN27wsS3ON1ZcvdRbhXwe0MU0bgIXmD070e
RWKLnhAQACHalcZLs54Lcc7YmOl6mgqtTfDQC25Y04yw3MTLa8DneCFwPgoXSNeSUi7WeHrPxrlw
YdoUeAKL1hzdN6tbjhyi+GYm/XgwDuq5/id45Dv9vXq562/yT3QHZPeK2MF2vzsNCHmKodveBwfF
JAZpRkD60pgpaLVrl9m9OKYrBUJYRN57HatEtC2K9UwP3uaU2pVKtrxHhuGqnvAAgTPqETOeeR9R
RC7KwsFKEB7EbtXJmsjhTLT0sl109rO+EM1sqGalNrHyDvIrsZ6BkxlZFrh52GK68h5FP7vPFuTg
DYNn1ZRYbtSNXDxggYY7uUbghwUi2B8mBBF6APRkaEJp3apndaSOD3E2w5+4GN8CpDl3msUIn2LQ
AjoEDAMtt3rwg+ChWfgatCMiOYld8EA9XvDBkZE3YsV3R+etILYoymgAMxDhpn1oEKAqBeQOHet2
bW0Te65HtLInzPlKjS/XWdXYKhsisxtzCWw1lBU4GyKnc9TglKGfASqWW0vdE5PZBZHOwLfFUguf
QW79Ym8Germ7GctLPXQ1cFKGx2cQXNFpdxVyInk/zcb9s8s5zgKH1bu4kmNg7ePqE56ERL3kaYeg
FOPm5Syp5JBVz+rAXhzpxh3EDjQ8eVoJY+D7cnavZvBaXHsfvHYBtg7eX3b0S8//S/oDKFD8tWlp
AEMj1ket0vgn3cwc5QGfIMwqXo9MZs+6CvrzCV83+uXbonakX3r+v6Nfev67+OVWckclbY5aexfJ
ex95y1zoJWTeaapwSud8NGzDMBe2cQrl2vttYMfHMigz9YxrI98KsSg4+x4tGDhP7Oc3n/T7dvAk
6uSFSY4RBcWembO3Rm7m9EyET0yU+xzyz0z8Da+dZdaHhe2ThinHjtk1zC8L229m1r9c2D7IrLie
tSVOvfKmmz2OFLsATbcYaxO3+Mj9/1XIbebfhhqOEvI4+Xw/SkibJd61yBuCp8PNf4ioc/z5/7fS
xYf/M36Pj+MvEO1N/zOIm9YCj7WBP2HI3y/U7PLtZqqLAAAQ9klEQVR42u3caZBc13Uf8N/rZfZ9
3zErQGLhCgJcwU0kTUo2Q5OybEpFyS5HkUXa5aXiuPQhSTkpJ7JdicubrM12ZEY0KZoSFUWEFZEg
RUGCDAIECRDrDAbLEPsyg8Hs0935MM1W93T3QOAGumr+70u/8+697977v/fcc869r1nEIhaxiEW8
JwjnfRIICyQWzD2XZhEfCOSjMuIe/8kyb5jImzfkev/ZdfqNXOpmLIJQHnmRa9znTo0L5I1Y4SPu
0bE4Mz8IyEdli9tUucoteVNQ5071LrdO0aVuxiLyU9muHeVWKc6TItCqF8W6lV3qZiwiH5UF2pQh
rFtN3rytmhFoU3upm7GIfFQW6lWJkBZteXP2JSlsV7u4Wl565KPyLaXZoCdPmmo9IqBeyyKVlx65
aarUnPrVoyBnmlZLk65MhWV50izifUQuKgOdKSqLkqo2G42WJOdiRIfyS92QReSiMqxNRep3p6ac
+bpSBlGgU/UC7wiS18U8SX/+/uFC9bm4ct5nRHLIwrrSZmKTDq9nBfDK9ShMVbxbk715m7VMixnn
DTktniav06EYZxwwnpUvpFa7MlMOO5aW771CoF6HYjOOedPMO+jRJs2KTTjg1AUCn+8qclFZoFtJ
6q5en6jpeWnq9aatj7Va88ZrA8utNeaoGWfmdd11agX2OZmTygbXaTbiZSfeFyobrVFn3DYn3wGV
UR1Wq3XauNOXmso67Wl35XqVZlHZqC8tflukW3EOOiDkNg+ZtNdJe8XS5Ev8oh5xP/Sqk1n5wno8
aKkTTttu9j3viUCXB3UaV2G7sbddTrFrPKzJYQfseR+GYArZVAZ6Nadp+pBudc7OS9WeERYI61SZ
h8pAiyaBYJ73GajQpxNv5gz8BSr1WaJS/QLBw3cTlZZqE7PtHdnjEc2WqVK4QHDlPUF2JwU6VGVI
GjOohVJ9GcG6sPY8di7EJBBLm5GQEBdDPO+Mi5tJpnt/MFeT2DvUAIlkCfPb+54je1YWZhg90KTT
jzIaWKErYyaFdGuy+wIdlciSxNOehAVJSThVdiLZNXEUqBAzZmZeOYFCxcKYNJHWfYGQtwZCoEC5
uPNZubPrkzl0QqLKJYybzkFNoECpkFkTZpL5Esn2pLc3lLJo594RUqBUYMJkKt1cikRW69KlYaUK
nTeZqxXZVJZbOk/B1FmqIIPKGn2iGWmqtFoYQQ7JnGzWjHarldnvdaVusNywzcJmkTAp6hqrtZgx
ZKfXjKZKaLTCZRoV4Jy9djlgDFFdLlfhqG0mXe0qzWYdsNmerHU/H6JaLbVMs4Rhh+y011Ta82or
XKlRxKSzDnnDgZwzutyV2hUas82AQItVlmoWGHbYdvtMKNarTdRJu9MWswbLlZpx1IBx1frUKhW3
R7/p+XRmU1mbpU4jLlfnUJqkKysyW6RHeaqLLw7TQu7xu2p839Ou8HGtBs2aTXZMu0d83CpRs8Yd
9BVPGkHEMr/qHs1KRAQmnbTbk541rMTtHtNor68LecQyEbNG/Ysve3GB7fQ5JFDuZg9brUEJpo3Y
4X9b74yEOT30S+7XrUTYrCnDNvuyl7KWg0L3ekyfqI36RV3tk27XqAQzhm31pO+I+JhfUWjAn1qf
tJ8L3Ou3NBj2D74iZp1rxVWpsc9mOzMYyUllh/qsGbRUt8OpUVBsubocVFa8TSoTIhq0KrdKnZVa
0aDcsISEYh9VqMRxYTXqNSgT8w3n9PotD6lxzkFnhLVo16lFzFMCVVrUKFGnSLHjKtWp8BFVprx4
gfV3VoH7PeoaBUYNmVKvTZseS3zVcfT4bQ9pMOOoYUVadetQacr2tFmfEHW337ZW4HXP6neT33ez
EifsN6Neu/v0KfWUU6pVq3GHLY6ARuusVGDGoPOW6DIipNLrin3KP12IytA82/S0HzrjqONpspid
vq7QMtem1GzUEhXefFtUBhImzQp0WyrsTecMGlIshGLL7PZl25S62S9qc7nPOuT/WenDapz1jGcc
FnGjf+sKq9znBedNm0SJPtt9wXYrfMIKUdf5kG0ZHu58xMX1esRagYO+ZYMRa33Cckv9qkFPC7nb
QxpM2OAf9av1K/6NEnca8sepOT8rbLXHrBHY4vO+o9rH3a7AHn9vgymX+aTbXeY39HvBXe5RbI1m
RxC2wlUKzPqJnyjWa0ypY37sjHV6Ncyv9nwqo9rSwgPs81/1SxhL08wzvm+jkActT1sxmzTY9bao
lCq7zJgXPOOAYSfcqkCAff7CPzorZIuYT6m2zBovOe81B23zJa+BMbdaLqpBqXMpI+QV/9N3jdpo
1Oe0i+rSYFhYSCxpXWdiWpEPuVZgxFP+whEx25zxB7p1usfLyt2tATv9mZdMCzutzG2KtGgSFses
Kb0esw6v+FP/R8IdblVgyN/5WyexwwnlbnKZj/hzG92sQqtOr4orslovjnrRMZWKhJQ56rS4WaPZ
K/58Kqssy7BNSxUbzur4SZMKVGWYPuXahN6h4zBrgz+yxYyEInEBJj3vm84gZr8nrXGTqMu02uSI
kGFn1KvR4BbLhSXMpNma477vn53DiI32axcX06zNUlG7/DhrUUiYVu1GVWJe8U1DEhi23jqtCq3Q
q94qjNlki2nEbPZfPKtAv5NKBAi7xp0+osgmn/ecKY3WaRO3x0YTKhDXb6frFLpaoy0OWanKaj9w
UrMrlOOgLaadNWiZcSXuMiRkKMvTz6KyRnOGr9ntLltzxj6Wu28e6V1KF1gtFw5hzT09Y72tqfE2
t2KP2Z2mDofss0ZEh3YHHHK51Xo0qVevS50g6dcFQgJMOZ5SeDMmJMSMafEhdwn5tl05qAyp1yBk
3A77U/UetsOdmtVqU6sKx2xLhUVmbbEF1CbfXONjKpRLGPQvplCsWaG4Dr/utDBmFVsjLNCpy8te
0qfSDZqdstpKgQnbHcCsQYes1KjcUkett+NCVLapz7gvdaMldmZ1fci1VmaYR6V6LkBlLEuS7lHB
cYezop+jzmYo92ExUcXCmj3ifkvUKhAz6rxhlfNMtrifukGhpKeZUKpNk5DGDL2SSPqxEWXCiJtO
cy5mnDSGcs0KhDDqSFpt5w/VMn3J1q2yzjOmFSpCSI/uZJ0SyToGitU5Y6OHNFqi015X6MKA55Na
8ZyXTGoSF9Fvs3MLUxnRmWWbXu5ae7JoaHDDvD3KiPY8x7XmKhxVOK+bA+EkxW91w0xSqaYjnHFs
OhARSJhS4sMe1SZu0KsGHRa43y1ZgzMzWBgkJXPxp6m0diVSAYpoMlITiKaVFogIIW4qGX0OieTw
ltOH3aCwTpf5dQM2J98cN2iTk6mBNSMh7JxtZu2xW4NKVxjQplDCbq8kh1PCm55TLmLChMnsbs5s
eGHyeFY6mtziOafmSfuszYpUtujQn6Npc7GJWt0K0qoQUqUEcWdNJzsn1y5feUbstliDiFnDWt2u
Abv9te84Y0qjlW5KvvOtHLl2DROmTUsIK05rf0iNCkQExswirFJp2ps7VOKsITWmUKYpGcaAag1C
zqZKjPmRr+ryGc2u97D9JowhYa+/tCOphhPJvc2YSbMO2ugKZW42rUXgmE0ZPT+xkEecGYOt0Jt1
WDLkat3zZIWuy3F8q0JHjkB0wh6nUOFW16QUWtgyt6vGhF1GkuM7V8eXuFJL6m6pZcKm9JvUJmLa
Vv/XQaOmFaoQIsMuTWTVJiRhyBFxgT5XpnZdG12tCuOOOOqY2aSJ81adqq1ShZOOOOQY6l2Z0kxl
7veH/sSntZoWx1nP+IYnvGhaqV9wh3N2GRfWrtmYc0aMGNNundusVCZh2CuOi7rWA7qxwwt5Nily
IHNW1mjM0Z191tiWYfy2uDVH+LxCp4IsIzlug6s9IOIa/169N8yI6vYx9yqUsMuPTCXdjlymUYE7
DHjccYGlHtaDYVsdEBMIq07qkRYPWCuKJn22J9VldmtCGLTDuALdHlVsq2k1brNOAY571QEvukOr
5R405JC4Sne5UtiU1w0qsdNK5da52/NGFfqQT1stKu6fk8G9CSfNGPSEy11liU/aZ4N7XanXLzlg
u1mFrvebVgts8HlnxOzyhh61SkVM2Z0WmLkoKkNasx1PVLrJtwylSS6zKqcq7FXq/DxpwnZPW+oK
xe621IDzynToVIxBj3tNcZLKSIaWmAuIz6rzGStsEXW9m5SZ9rLNxg26UdRVHvaiEnf4sCVgpU/7
YrJ+obQS56zakEJTNrjNvYrcosse45p1ahCY8mPbTdjgBR9V5kFVNpm23J3asdtzTgl5xgqrXOYP
rNGvzn2uFnHCBodFRMwF4gOzfuAJreqt8wlf9XVNGt0n6lnHdXrAbYqcNZRUpG960VptinHQtqze
/BmpLNKZ5xuR61zlSMpnrLAuT7pWXSazPgYa8z3lPm2lEr16xJKGzJT9/penjCXjOvP3BebMouO2
avBz7hAoF3HORl8xILDeFZZr8u98VFSZfnss16bE1TqTJWbux4SSbY7a6c8E7lChS6d40gA76wVf
dBCH/I0i96h1v9vFlSk1Zbcv+IGYmO+p9ahlVukypkClwGnf8qzzijKG+Yhvu8pDyj3gVU+p9knN
ft71JpWoE3bUt309SeWoH+vXhlmveSWXeZMPPz0JEGh1l9tynisod8jG1ALf51E9OUubcNi4o1ny
SQP2GROImTZt3IjD1vsrzzpp7jR8tRFbvezN1IZOo1bTNvua74loEnXOTt/0JZvMiDli0IQChaJG
PO+vPe24iFGv+JFptabs9oJ9STu1TK9ih7zsFaOO6DcpjBlTxpyx1T/4km1iSDhirymBhGJRo96w
3ld8J+kETNnvsPGklRtz2CZP+JoBhVpVG7fD8w4kwwsjak0757xNNhkRVahcmZATNvpbXzOQmigJ
V1khYsxTnvuZd3GkryVha33Wx3OmStjgsWRYLuoRn8/zacFBT3rR+pz6PaxZpzqVCsWcd9qAoaRf
FtWiRdRZh1OxpbA67UqNGjRiiT7VYo4ZcCLlQkS16lIu5qwhR82o0qPUGUcUaVFqzCGnkt1Uolud
WUcMmUZYgx6NKkRMOWfIPufS6h5o1KlGjagRxww5krGJFdVmiRoRCSOOOWREQlSTFkVGHUzuolBq
mXIJ0/Y5rVyfVtUKTDjrsIEM06bTf/egiF0+59m3dzYoZIW/EpPIeZ30SJL2Oo/nTTXgc25ZcOAE
QiLCOdyOXI5I+iHEfAcb58vn51j4LW/VaOHjnSH5DkMGOeuVT5JZy8xSw8pUa/cb+iVM+YKOiyPw
p+o0btAWJ/OsglVu9l2n0O2avGdtDnlpwZD6XDQlnvfZQrJ84zOR9/5CJV6oRj9Nn7jA8wtLc783
HaVud7MWqy3BgPUZhuZFo9vjpvPMuNetQ5HfM5onxVGfWfzS8m2jzn9zVlxCwjH/8eK/jsucX/v9
D9/NYzV1uFZEszvTIiDpOO4Lnr4Yi2sRGZgxYsqU07b6osedvtgC5v8XwTHbxXTm+AYkatwml/u1
eefxYNpWf+xrF//6RaSQMGXIT2zwbc86/O4UWutTfmgyS4Hu9/u+bDxLfsrfu2nesa1FXDwCIaFk
bPZdQ9haX3RiHmXT3kxp87euWa/5nbyf0y7iA4FGn/Wq2Twmztx13jfcuWjqfPBR5AZPJM+9ZV8x
/T63+Ecv/3rQ6j/YlSMkMGa9X8j77yGL+ECi2N2+Oc+XPOCP9L1PH+Us4l1EoNcf2m5CQsJp3/Pw
Ah/7LOIS4Wdd6Srd4JetNOkFT9r7Dj4lXcR7hJ/daClyoyuN+WGO83eL+ADg/wM5uMfpaQMySQAA
ACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wMy0xMlQxMTozNzoyMCswMDowMPgehQ0AAAAldEVYdGRh
dGU6bW9kaWZ5ADIwMjItMDMtMTJUMTE6Mzc6MjArMDA6MDCJQz2xAAAAAElFTkSuQmCC" />
</svg>
);

export default Logo;
