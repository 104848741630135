import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CustomerIcon from '@material-ui/icons/EmojiPeople';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { translate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardIcon from '../../control/view/card-icon';
import Button from '@material-ui/core/Button';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const CommissionNbDashboard = ({ value, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={CustomerIcon} bgColor="#f00810" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                <Link href="#">
                {translate('dashboard.commission')}
                </Link>
            </Typography>
            <Typography variant="headline" component="h2">
                {value}€
            </Typography>
        </Card>
        {/* <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button href="https://marmelab.com/react-admin">
                <CustomerIcon style={{ paddingRight: '0.5em' }} />
                {translate('pos.dashboard.welcome.aor_button')}
            </Button>
            <Button href="https://github.com/marmelab/react-admin/tree/master/examples/demo">
                <CardIcon style={{ paddingRight: '0.5em' }} />
                {translate('pos.dashboard.welcome.demo_button')}
            </Button>
        </CardActions> */}
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(CommissionNbDashboard);
