
import React, {Link} from 'react';
import { SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.location.show')}: {record ? `${record.name}` : ''}</span>;
    
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowLocation = ({staticContext,permissions, ...props}) => {
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
        
            <TextField source="name" label = "Nom"/>
            <TextField source="address" label = "Adresse"/>
            <TextField source="postal_code" label="Code postal"/>
            <TypeTextField source="type" label = "Type"/>
            <TextField source="lat" label="Latitude"/>
            <TextField source="lng" label="Longitude"/>
            
        </SimpleShowLayout>
    </Show>
};


export default ShowLocation;