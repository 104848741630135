import React, {Fragment} from 'react';
import { List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {locationDict, locationTypes} from '../../util/variant'

const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.name.list')}</span>;
};

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Nom de Jockey" source="name" alwaysOn resettable/>
        
        
    </Filter>
);

const ListName = ({permissions, ...props}) => {
    if(permissions !== 'Admin') return <span>Aucune permission</span>;
    return <List title={<Title/>} {...props} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Nom de Jockey"/>
            <TextField source="email" label="Utilisé par"/>
        </Datagrid>
    </List>
};

export default ListName;

